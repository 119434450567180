.error-section {
  background-color: #e7ffff;
  height: 100vh;
  text-align: center;
}

.error-section .error {
  font-size: 150px;
  color: #008b62;
  text-shadow: 1px 1px 1px #00593e, 2px 2px 1px #00593e, 3px 3px 1px #00593e,
    4px 4px 1px #00593e, 5px 5px 1px #00593e, 6px 6px 1px #00593e,
    7px 7px 1px #00593e, 8px 8px 1px #00593e, 25px 25px 8px rgba(0, 0, 0, 0.2);
}

.error-section .page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.error-section .back-home {
  display: inline-block;
  border: 2px solid #222;
  color: #222;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: color 0.2s linear, background-color 0.2s linear;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.error-section .back-home:hover {
  background-color: #222;
  color: #ddd;
}

@media screen and (max-width: 768px) {
  .error-section .error {
    font-size: 4rem;
  }
}
