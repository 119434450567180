/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Potta+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

* {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  --darkColor: #393f3f;
  --lightColor: #bbc2c0;
  --orangeColor: #ea5421f2;
  --white: #fff;
  --black: #000;
  --transparent: #0000;
}
.font-roboto {
  font-family: "Roboto", sans-serif;
}
.font-potta {
  font-family: "Potta One", cursive;
}
.font-raleway {
  font-family: "Raleway", sans-serif;
}
.font-lobster {
  font-family: "Lobster", cursive;
}
.font-times {
  font-family: "Times New Roman", Times, serif;
}
.font-courier {
  font-family: "Courier New", Courier, monospace;
}
.centralise {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.display-none {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.text-area-editor {
  height: 100%;
  width: 100%;
  resize: none;
  border: none;
  box-sizing: border-box;
  padding: 0.2rem 0.4rem;
}
.text-area-editor:focus {
  outline: none;
}
.text-area-editor::-webkit-scrollbar {
  width: 10px;
}
.text-area-editor::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: rgba(234, 84, 33, 0.9);
  border-radius: 10px;
}
.button-base {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #0000;
}

.container-center {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .container-center {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
